import React from 'react';
import PropTypes from 'prop-types';

const UnderlinedHeadline = ({
  as: Headline = 'h2',
  color = 'royal-blue',
  containerClasses,
  children
}) => {
  const lineClasses = `inline-block w-28 h-2 bg-${color} mt-8`;

  return (
    <div className={containerClasses ? containerClasses : null}>
      <Headline>{children}</Headline>
      <span className={lineClasses}></span>
    </div>
  );
};

UnderlinedHeadline.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  color: PropTypes.oneOf(['royal-blue', 'royal-blue-medium', 'black']),
  containerClasses: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default UnderlinedHeadline;
