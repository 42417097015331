import React from 'react';
import PropTypes from 'prop-types';

import CheckMarkIcon from '../../assets/icons/check-mark.svg';

const CheckedList = ({ items, parallel }) => (
  <ul
    className={`text-xl leading-5 md:text-2xl ${
      parallel ? 'flex flex-wrap justify-center -my-4' : 'space-y-8'
    }`}
  >
    {items.map((item, index) => (
      <li
        key={index}
        className={`flex items-center ${parallel ? 'mx-8 my-4' : ''}`}
      >
        <CheckMarkIcon className="flex-none h-6 text-royal-blue mr-6" />
        {item}
      </li>
    ))}
  </ul>
);

CheckedList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  parallel: PropTypes.bool
};

export default CheckedList;
