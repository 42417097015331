import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Title = ({ prefix }) => (
  <Helmet title={`${prefix} | Eventspace by CodeCamp:N`} />
);

Title.propTypes = {
  prefix: PropTypes.string.isRequired
};

export default Title;
