import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const Button = ({ to, as: HTMLElement, variant, children, ...props }) => {
  let classes =
    'inline-block text-lg uppercase px-12 py-3.5 rounded md:text-xl hover:bg-black';

  switch (variant) {
    case 'royal-blue':
      classes += ' bg-royal-blue text-white hover:text-white';
      break;

    case 'mint':
    default:
      classes += ' bg-mint text-black hover:text-mint';
  }

  const buttonProps = {
    className: classes,
    ...props
  };

  if (to) {
    return (
      <Link to={to} {...buttonProps}>
        {children}
      </Link>
    );
  }

  return <HTMLElement {...buttonProps}>{children}</HTMLElement>;
};

Button.propTypes = {
  to: PropTypes.string,
  as: PropTypes.oneOf(['button', 'a']),
  variant: PropTypes.oneOf(['royal-blue', 'mint']),
  children: PropTypes.node.isRequired
};

export default Button;
