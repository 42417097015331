import React from 'react';

import { Title, Button } from '../components/global';

const ErrorPage = () => (
  <>
    <Title prefix="Fehler" />
    <section className="flex justify-center items-center min-h-screen bg-champagne-blue">
      <div className="text-center px-4">
        <h1 className="text-h2 mb-10">Hier ist etwas schief gelaufen</h1>
        <Button to="/">Zurück zur Startseite</Button>
      </div>
    </section>
  </>
);

export default ErrorPage;
